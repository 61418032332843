import styled from "styled-components";
import { ChevronDown, ChevronUp, Dash } from "react-bootstrap-icons";

export const StyledSLContainer = styled.div`
  overflow-y: auto;
  max-height: 95vh;
  overscroll-behavior: none;
`;

export const StyledSLSelectionContainer = styled.div`
  overflow-y: auto;
  max-height: 75vh;
  overscroll-behavior: none;
  border: solid #608cdf 1px;
`;

export const StyledSLText = styled.div`
  margin-bottom:5px;
`;

export const StyledSLUl = styled.ul`
  margin-top: 2px;
  ul,
  li {
    list-style-type: none;
    list-style-position: inside;
    margin-left: 5px;
    padding-left: 5px;
    overflow-y: auto;
  }
  @media (max-width: 600px) {
    padding-left: 2%;
    ul,
    li {
      list-style-type: none;
      list-style-position: inside;
      margin-left: 2px;
    }
  }
`;

export const StyledSLDownArrow = styled(ChevronDown)`
  font-size: 12px;
  margin-right: 3px;
  border-radius: 100%;
  border: none;
`;

export const StyledSLUpArrow = styled(ChevronUp)`
  font-size: 12px;
  margin-right: 3px;
  border-radius: 100%;
  border: none;
`;

export const StyledSLDash = styled(Dash)`
  font-size: 12px;
  margin-right: 3px;
  border-radius: 100%;
  border: none;
`;

export const StyledSLNavy = styled.span`
  color: #000035;
`;
